export const wrapMarkWord = (word) => {
  //태그로 감싸기
  return `<mark>${word}</mark>`;
};
export const removeSpace = (word) => {
  //공백제거
  return word.replace(/ /g, '');
};
export const compareRegardlessCase = (word) => {
  //대소문자 구분없이 비교
  return new RegExp(word, 'gi');
};

export const comparePerfectCase = (word) => {
  //대소문자까지 비교
  return new RegExp(String(word), 'g');
};

export const makeTagHighlight = (word, sentence) => {
  //태그하이라이팅 - 대소문자구분, 띄어쓰기 제외 검색
  const regexp = compareRegardlessCase(removeSpace(word));
  const matchedWord = sentence.match(regexp);
  return sentence.replace(regexp, wrapMarkWord(matchedWord));
};

export const makeHighlight = (word, sentence) => {
  //그외 완전비교 하이라이팅
  return sentence.replace(comparePerfectCase(word), wrapMarkWord(word));
};
